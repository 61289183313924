import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../screens/Dashboard/Dashboard";
import Users from "../../screens/Users/Users";
import Scheduling from "../../screens/Scheduling/Scheduling";
import Courts from "../../screens/Courts/Courts";

function AppRoutes() {
  return (
    <div>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/scheduling" element={<Scheduling />} />
        <Route path="/courts" element={<Courts />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default AppRoutes;

import React from "react";
import "./index.css";

export function CardCourt({ onClick, court, onStatusChange }) {
  const getStatusColor = (status) => {
    switch (status) {
      case "available":
        return "bg-green-100 text-green-800";
      case "occupied":
        return "bg-red-100 text-red-800";
      case "maintenance":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="card" onClick={onClick}>
      <div className="title">
        <div className="name">{court.name}</div>
        <div className={`status ${getStatusColor(court.status)}`}>
          {court.status === "available" && "Disponível"}
          {court.status === "occupied" && "Ocupada"}
          {court.status === "maintenance" && "Manutenção"}
        </div>
      </div>
      <div className="type">{court.court_type}</div>
      <div className="value">
        <img
          src={require("./../../assets/icons/schedule.svg").default}
          alt="schedule icon"
        />
        <span> {`R$ ${court.value_appointment}/hora`}</span>
      </div>
      <div className="ocupation">
        <select
          value={court.status}
          onChange={(e) => onStatusChange(court.id, e.target.value)}
        >
          <option value="available">Disponível</option>
          <option value="occupied">Ocupada</option>
          <option value="maintenance">Manutenção</option>
        </select>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "./index.css";
import "../Courts/button.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptBR from "@fullcalendar/core/locales/pt-br";
import listWeekPlugin from "@fullcalendar/list";
import {
  Modal,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  CircularProgress,
} from "@mui/material";
import { getData } from "../../services/appointment-service";

export default function Scheduling() {
  const customer_id = "1c2b519d-c539-4c6d-82c7-abfc00e351a4";
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCourt, setSelectedCourt] = useState("");
  const [events, setEvents] = useState([]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    // Fecha o modal ao clicar no botão "Cancelar"
    setIsModalOpen(false);
  };

  const handleContinue = () => {
    // Ação ao clicar em "Continuar" - pode ser salvar ou continuar com o agendamento
    console.log("Quadra selecionada:", selectedCourt);
    // Você pode adicionar qualquer outra lógica necessária aqui
    setIsModalOpen(false);
  };

  const handleSelectChange = (event) => {
    setSelectedCourt(event.target.value);
  };

  const handleDateClick = (info) => {
    setIsModalOpen(true);
  };

  const handleEventClick = (info) => {
    const newTitle = prompt("Edite o título do evento:", info.event.title);

    if (newTitle) {
      const updatedEvent = {};
      info.event.setProp("title", newTitle);
      info.event.setStart(updatedEvent.start);
      info.event.setEnd(updatedEvent.end);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const data = await getData({ customer_id: customer_id });

        setEvents(
          data.appointments.map((item) => {
            return {
              title: item.customer.name,
              start: item.start,
              end: item.end,
            };
          })
        );
      } catch (error) {
        console.error("Erro ao buscar os dados", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  return (
    <div className="scheduling-screen">
      <div className="scheduling-header">
        <span className="title">Agendamentos</span>
        <button
          onClick={() => setIsModalOpen(true)}
          className="button button-primary"
        >
          Novo Agendamento
        </button>
      </div>
      <div>
        {loading ? (
          <div className="loading">
            <CircularProgress sx={{ color: "#ff5c00" }} />
          </div>
        ) : (
          <div>
            <div>
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listWeekPlugin,
                ]}
                initialView="timeGridWeek"
                weekends={true}
                locale={ptBR}
                events={events}
                noEventsText="Nenhum agendamento encontrado."
                allDaySlot={false}
                headerToolbar={{
                  start: "prev,next today",
                  center: "title",
                  end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                }}
                views={{
                  dayGridMonth: { buttonText: "Mês" },
                  timeGridWeek: { buttonText: "Semana" },
                  timeGridDay: { buttonText: "Dia" },
                  listWeek: { buttonText: "Lista" },
                }}
                height="85vh"
                dateClick={handleDateClick}
                eventClick={handleEventClick}
              />
            </div>
            <div>
              <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    padding: 4,
                    boxShadow: 24,
                    width: "50%",
                  }}
                >
                  <h2 id="modal-title">Adicionar Agendamento</h2>
                  <div>
                    {/* Campo de seleção de quadra */}
                    <FormControl fullWidth>
                      <InputLabel id="court-select-label">
                        Selecione a Quadra
                      </InputLabel>
                      <Select
                        labelId="court-select-label"
                        id="court-select"
                        value={selectedCourt}
                        label="Selecione a Quadra"
                        onChange={handleSelectChange}
                      >
                        <MenuItem value={1}>Quadra 1</MenuItem>
                        <MenuItem value={2}>Quadra 2</MenuItem>
                        <MenuItem value={3}>Quadra 3</MenuItem>
                        <MenuItem value={4}>Quadra 4</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/* Botões de Cancelar e Continuar */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleContinue}
                    >
                      Continuar
                    </Button>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

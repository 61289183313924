import React, { useState } from "react";
import "./index.css";
import "../../button.css";
import "../../../../aws-exports";
import { AddScheduleService } from "../../../../services/appointment-service";
import { format } from "date-fns";

export function ScheduleModal({ closeModal, court_id }) {
  const [formData, setFormData] = useState({
    startTime: "",
    endTime: "",
    paymentMethod: "",
    hasRentalPrice: false,
    rentalPrice: "",
  });

  function transformTimeToFullDate(time) {
    console.log(time);
    const currentDate = new Date();

    const [hours, minutes] = time.split(":");
    const fullDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      parseInt(hours),
      parseInt(minutes)
    );

    return format(fullDate, "yyyy-MM-dd'T'HH:mm:ss");
  }

  const onAdd = () => {
    const payload = {
      start: transformTimeToFullDate(formData.startTime),
      end: transformTimeToFullDate(formData.endTime),
      user_id: "b4f85468-00e1-707b-f8fa-ed2467b3d2d0",
      court_id,
    };

    console.log(payload);

    try {
      const data = AddScheduleService(payload);

      return data;
    } catch (error) {
      console.error("Erro ao adicionar agendamento", error);
    } finally {
      //setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.endTime <= formData.startTime) {
      alert("O horário final deve ser maior que o horário inicial!");
      return;
    }
    onAdd();
    //onClose();
    setFormData({ startTime: "", endTime: "", paymentMethod: "" });
  };

  return (
    <div className="schedule-modal">
      <form onSubmit={handleSubmit} className="form-container">
        {/* Campo de Horário Inicial */}
        <div>
          <label htmlFor="startTime" className="label">
            Horário Inicial
          </label>
          <input
            type="time"
            id="startTime"
            required
            value={formData.startTime}
            onChange={(e) =>
              setFormData({
                ...formData,
                startTime: e.target.value,
                endTime:
                  e.target.value > formData.endTime ? "" : formData.endTime, // Reseta endTime se for inválido
              })
            }
            className="input"
          />
        </div>

        {/* Campo de Horário Final */}
        <div>
          <label htmlFor="endTime" className="label">
            Horário Final
          </label>
          <input
            type="time"
            id="endTime"
            required
            value={formData.endTime}
            min={formData.startTime || "00:00"} // Restringe para ser >= startTime
            onChange={(e) =>
              setFormData({ ...formData, endTime: e.target.value })
            }
            className="input"
          />
        </div>

        {/* Campo para indicar se a quadra tem valor para locação */}
        <div>
          <label className="label">A quadra tem valor de locação?</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={formData.hasRentalPrice}
              onChange={(e) =>
                setFormData({ ...formData, hasRentalPrice: e.target.checked })
              }
            />
            <span className="slider"></span>
          </label>
        </div>

        {/* Mostrar o campo de valor de locação somente se o switch estiver ativado */}
        {formData.hasRentalPrice && (
          <div>
            <label htmlFor="rentalPrice" className="label">
              Valor por hora
            </label>
            <input
              type="number"
              id="rentalPrice"
              value={formData.rentalPrice}
              onChange={(e) =>
                setFormData({ ...formData, rentalPrice: e.target.value })
              }
              className="input"
              placeholder="Digite o valor por hora"
            />
          </div>
        )}

        {/* Botões */}
        <div className="buttons-container">
          <button type="button" className="button button-secondary">
            Cancelar
          </button>
          <button className="button button-primary">Adicionar</button>
        </div>
      </form>
    </div>
  );
}

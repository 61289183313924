import React from "react";
import { createRoot } from "react-dom/client";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./index.css";
import "primeicons/primeicons.css";
import App from "./App";
import "primeicons/primeicons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../src/components/IconsLib/styles/index.css";
import { BrowserRouter } from "react-router-dom";
import "./colors.css";

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

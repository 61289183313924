import React from "react";
import { Calendar, Clock, User, DollarSign } from "lucide-react";
import "./index.css";
import { format } from "date-fns";

export function ScheduleList({ appointment }) {
  const formatData = (date, period) => {
    const date_formated = new Date(date);
    if (period === "month") {
      return format(date_formated, "dd/MM/yyyy");
    } else {
      return format(date_formated, "HH:MM");
    }
  };

  return (
    <div className="schedule">
      <div className="info-schedule">
        <div className="user-schedule">
          <User className="w-4 h-4 text-gray-500" />
          <span> {appointment.customer.name} </span>
        </div>
        <div className="month-schedule">
          <Calendar className="w-4 h-4 text-gray-500" />
          <span> {formatData(appointment.start, "month")} </span>
        </div>
        <div className="hour-schedule">
          <Clock className="w-4 h-4 text-gray-500" />
          <span>
            {formatData(appointment.start, "hour")} -{" "}
            {formatData(appointment.end, "hour")}
          </span>
        </div>
        <div className="value-schedule">
          <DollarSign className="w-4 h-4 text-gray-500" />
          <span>{`R$ ${appointment.court.value_appointment}/hora`}</span>
        </div>
      </div>
      <div className="status-schedule">
        <span> ATIVO </span>
      </div>
    </div>
  );
}

import CryptoJS from "crypto-js";
import { setIsLoaing } from "../../App";
import { signIn, confirmSignIn } from "@aws-amplify/auth";
import config from "../../aws-exports";
import { showToast } from "../../components/UI/Alert/Alert";

function getUserAttributes() {
  let response = null;
  let token = null;
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    if (key.includes("LastAuthUser")) {
      response = localStorage.getItem(key);
    }
    if (key.includes("kndmaster.idToken")) {
      token = localStorage.getItem(key);
    }
  });
  const initials = response
    .split(" ")
    .map((word) => word[0].toUpperCase())
    .join("");
  writeUserAttributes(token, initials);
}

export async function handleLogin(
  setUser,
  setResponse,
  setNextStep,
  username,
  password
) {
  try {
    setIsLoaing(true);
    const clientId = config["aws_user_pools_web_client_id"];
    const clientSecret = config["aws_user_pools_web_secret_id"];

    function generateSecretHash(clientId, clientSecret, username) {
      const str = username + clientId;
      const hash = CryptoJS.HmacSHA256(str, clientSecret);
      const secretHash = hash.toString(CryptoJS.enc.Base64);
      return secretHash;
    }

    const secretHash = generateSecretHash(clientId, clientSecret, username);
    const response = await signIn({
      username: username,
      password: password,
      secretHash: secretHash,
    });

    console.log(response);

    setResponse(response);
    if (
      response["nextStep"] &&
      response["nextStep"]["signInStep"] &&
      response["nextStep"]["signInStep"] ===
        "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
    ) {
      setNextStep("new_password");
      setIsLoaing(false);
    } else {
      const user_login = getUserAttributes();

      localStorage.setItem("user_signed_name", JSON.stringify(user_login));
      setUser(response);
      localStorage.setItem("user_signed", true);
      localStorage.setItem("quad-theme", "light-theme");
      window.history.replaceState({}, null, "/dashboard");
      showToast("success", "Logado com sucesso");
    }
    setIsLoaing(false);
  } catch (error) {
    showToast("error", error.message);
    setIsLoaing(false);
    localStorage.clear();
  }
}

function base64UrlDecode(str) {
  let base64 = str.replace(/-/g, "+").replace(/_/g, "/");
  return decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

function writeUserAttributes(jws, initials) {
  let parts = jws.split(".");
  let payload = JSON.parse(base64UrlDecode(parts[1]));

  const user_attributes = {
    name: "",
    family_name: "",
    email: "",
    initials: initials,
  };

  user_attributes["name"] = payload["name"];
  user_attributes["family_name"] = payload["family_name"];
  user_attributes["email"] = payload["email"];

  localStorage.setItem("user_attributes", JSON.stringify(user_attributes));
}

export async function handleSetPassword(response, password) {
  try {
    if (
      response["nextStep"] &&
      response["nextStep"]["signInStep"] &&
      response["nextStep"]["signInStep"] ===
        "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
    ) {
      const responseValidation = await confirmSignIn({
        challengeResponse: password,
      });
      if (responseValidation) {
        localStorage.setItem("user_signed", true);
      }
    }

    showToast("success", "Logado com sucesso");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error) {
    showToast("error", error.message);
  }
}

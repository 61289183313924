import React, { useState } from "react";
import "./index.css";
import "../button.css";

export function AddCourtModal({ closeModal }) {
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    hasRentalPrice: false,
    rentalPrice: "",
    rentalInterval: "",
  });

  const onAdd = (aux) => {
    console.log("ADICIONANDO ISSO", aux);
  };

  const onClose = () => {
    console.log("FECHANDO");
    closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd({
      ...formData,
      pricePerHour: Number(formData.pricePerHour),
      status: "available",
    });
    onClose();
    setFormData({ name: "", type: "", pricePerHour: "" });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="form-container">
        <div>
          <label htmlFor="name" className="label">
            Nome da Quadra
          </label>
          <input
            type="text"
            id="name"
            required
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="input"
          />
        </div>

        <div>
          <label htmlFor="type" className="label">
            Tipo de Quadra
          </label>
          <select
            id="type"
            required
            value={formData.type}
            onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            className="select"
          >
            <option value="">Selecione um tipo</option>
            <option value="Futsal">Futsal</option>
            <option value="Tênis">Tênis</option>
            <option value="Vôlei">Vôlei</option>
            <option value="Basquete">Basquete</option>
          </select>
        </div>

        {/* Campo para indicar se a quadra tem valor para locação */}
        <div>
          <label className="label">A quadra tem valor de locação?</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={formData.hasRentalPrice}
              onChange={(e) =>
                setFormData({ ...formData, hasRentalPrice: e.target.checked })
              }
            />
            <span className="slider"></span>
          </label>
        </div>

        {/* Mostrar o campo de intervalo de locação se o switch estiver ativado */}
        {formData.hasRentalPrice && (
          <div>
            <label htmlFor="rentalInterval" className="label">
              Intervalo mínimo de locação
            </label>

            {/* Unidade de tempo */}
            <div className="radio-input">
              <label>
                <input
                  type="radio"
                  name="timeUnit"
                  value="hours"
                  checked={formData.rentalUnit === "hours"}
                  onChange={() =>
                    setFormData({ ...formData, rentalUnit: "hours" })
                  }
                />
                Horas
              </label>
              <label>
                <input
                  type="radio"
                  name="timeUnit"
                  value="minutes"
                  checked={formData.rentalUnit === "minutes"}
                  onChange={() =>
                    setFormData({ ...formData, rentalUnit: "minutes" })
                  }
                />
                Minutos
              </label>
            </div>

            {/* Campo de intervalo com base na unidade escolhida */}
            <input
              type="number"
              id="rentalInterval"
              value={formData.rentalInterval}
              onChange={(e) =>
                setFormData({ ...formData, rentalInterval: e.target.value })
              }
              className="input"
              placeholder={`Digite o intervalo mínimo (${
                formData.rentalUnit === "hours" ? "horas" : "minutos"
              })`}
            />
          </div>
        )}

        {/* Mostrar o campo de valor de locação somente após o intervalo ser definido */}
        {formData.hasRentalPrice && formData.rentalInterval && (
          <div>
            <label htmlFor="rentalPrice" className="label">
              {formData.rentalUnit === "minutes"
                ? "Valor por minuto"
                : "Valor por hora"}
            </label>
            <input
              type="number"
              id="rentalPrice"
              value={formData.rentalPrice}
              onChange={(e) =>
                setFormData({ ...formData, rentalPrice: e.target.value })
              }
              className="input"
              placeholder={`Digite o valor por ${
                formData.rentalUnit === "minutes" ? "minuto" : "hora"
              }`}
            />
          </div>
        )}
        <div className="buttons-container">
          <button
            type="button"
            onClick={onClose}
            className="button button-secondary"
          >
            Cancelar
          </button>
          <button type="submit" className="button button-primary">
            Adicionar
          </button>
        </div>
      </form>
    </div>
  );
}

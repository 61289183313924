const token = localStorage.getItem(
  Object.keys(localStorage).find((key) => key.endsWith(".accessToken"))
);
console.log(token);

const path = "https://dev.api.squares.app.br/courts?limit=10";

export const getData = async () => {
  try {
    const response = await fetch(path, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Erro ao fazer requisição:", error);
      });
    return response;
  } catch (error) {
    console.error("Erro ao fazer requisição:", error);
    return null;
  }
};

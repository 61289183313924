const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:f169b4d2-6ed4-4e9a-9334-71f9212ace0f",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_wsVGrL8In",
  aws_user_pools_web_client_id: "2r6icdqapk9r43nofr63r6tv4s",
  aws_user_pools_web_secret_id: "TESTE",
};

export default awsmobile;

import React, { useState } from "react";
import "./index.css";
import UserMenu from "../UserMenu/UserMenu";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import Overlay from "../../Overlay/Overlay";
import * as LucideIcons from "lucide-react";

const sideBarItens = [
  {
    label: "Dashboard",
    icon: "LayoutDashboard",
    to: "dashboard",
  },
  {
    label: "Quadras",
    icon: "SquareSplitHorizontal",
    to: "courts",
  },
  {
    label: "Usuários",
    icon: "User",
    to: "users",
  },
  {
    label: "Agendamentos",
    icon: "Calendar",
    to: "scheduling",
  },
];

const active = window.location.pathname;

const SideBar = () => {
  const [sidebarOpen, setSideBarOpen] = useState(false);
  const [page, setPage] = useState(active !== "/" ? active : "/dashboard");

  return (
    <div className="sidebar-container">
      <div className={`sidebar ${sidebarOpen ? "" : "closed"}`}>
        <div className="title-side-bar">
          <img
            src={require("./../../../assets/icons/logo.svg").default}
            alt="logo icon"
          />
          {sidebarOpen && <h3>Squares</h3>}
        </div>
        <div
          className="sidebar-change-size"
          onClick={() => {
            setSideBarOpen(!sidebarOpen);
          }}
        >
          <Overlay
            label={sidebarOpen ? "Fechar" : "Abrir"}
            id="click-sidebar-element"
            placement="right"
          >
            <i
              id="click-sidebar-element"
              className="pi pi-bars icon-side-bar-click custom-target-icon-close p-text-secondary p-overlay-badge"
            ></i>
          </Overlay>
          <Tooltip target=".custom-target-icon-close" />
        </div>

        <div className="sidebar-items">
          <ul>
            {sideBarItens.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.to} onClick={() => setPage(item.to)}>
                    <div
                      className={`sidebar-icon-li ${
                        page.includes(item.to) ? "active" : ""
                      }`}
                    >
                      <Overlay
                        label={item.label}
                        id={`item-sidebar-element-${item.to}`}
                        placement="right"
                      >
                        <div className="item-sidebar">
                          <i />
                          {item.icon && LucideIcons[item.icon]
                            ? React.createElement(LucideIcons[item.icon], {
                                className: `icon icon-large ${
                                  page.includes(item.to) ? "active" : ""
                                }`,
                              })
                            : null}
                        </div>
                      </Overlay>
                      {sidebarOpen && <span>{item.label}</span>}
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <UserMenu sidebarOpen={sidebarOpen} />
      </div>
    </div>
  );
};

export default SideBar;

import React, { useState, useEffect } from "react";
import "./index.css";
import "./button.css";
import { getData } from "../../services/courts-service";
import CircularProgress from "@mui/material/CircularProgress";
import { Collapse, Box, Modal } from "@mui/material";
import { CardCourt } from "./CardCourt";
import { AddCourtModal } from "./courtModal/addCourtModal";
import { CourtSchedule } from "./courtSidebar/CourtSchedule";
import { Filter, ChevronDown, ChevronUp } from "lucide-react";

function Courts() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [courts, setCourts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    search: "",
    status: "",
    type: "",
  });
  const [selectedCourt, setSelectedCourt] = useState(null);

  const uniqueTypes = Array.from(new Set(courts.map((court) => court.type)));

  const hasActiveFilters = filters.search || filters.status || filters.type;

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleStatusChange = (courtId, status) => {
    setCourts(
      courts.map((court) => {
        if (court.id === courtId) {
          court.status = status;
        }
        return court;
      })
    );
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await getData();

        const aux = data.courts.map((item) => {
          item = { ...item, status: "available" };
          return item;
        });

        setCourts(aux);
      } catch (error) {
        console.error("Erro ao buscar os dados", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  return (
    <div className="court-page">
      <div className="body">
        <div className="header">
          <div
            className="filter"
            style={{
              borderBottom: isFiltersOpen ? "1px solid #00000026" : "none",
            }}
          >
            <div className="title">
              <span>Quadras registradas</span>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                className="button button-secondary"
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
              >
                <Filter className="w-2 h-2" />
                <span>Filtros</span>
                {!isFiltersOpen ? (
                  <ChevronDown className="w-2 h-2" />
                ) : (
                  <ChevronUp className="w-2 h-2" />
                )}
              </button>
              <button
                onClick={() => setIsModalOpen(true)}
                className="button button-primary"
              >
                Nova Quadra
              </button>
            </div>
          </div>
          <Collapse in={isFiltersOpen}>
            <Box>
              {isFiltersOpen && (
                <div className="modal-container">
                  <div className="grid-layout">
                    <div className="input-container">
                      <div className="input-icon"></div>
                      <input
                        type="text"
                        placeholder="Buscar por nome..."
                        value={filters.search}
                        onChange={(e) =>
                          setFilters({ ...filters, search: e.target.value })
                        }
                      />
                    </div>

                    <div className="input-container">
                      <select
                        value={filters.status}
                        onChange={(e) =>
                          setFilters({ ...filters, status: e.target.value })
                        }
                      >
                        <option value="">Status (Todos)</option>
                        <option value="available">Disponível</option>
                        <option value="occupied">Ocupada</option>
                        <option value="maintenance">Manutenção</option>
                      </select>
                    </div>

                    <div className="input-container">
                      <select
                        value={filters.type}
                        onChange={(e) =>
                          setFilters({ ...filters, type: e.target.value })
                        }
                      >
                        <option value="">Tipo (Todos)</option>
                        {uniqueTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {hasActiveFilters && (
                    <div className="mt-4 flex justify-end">
                      <button
                        onClick={() =>
                          setFilters({ search: "", status: "", type: "" })
                        }
                        className="clear-filters-btn"
                      >
                        Limpar Filtros
                      </button>
                    </div>
                  )}
                </div>
              )}
            </Box>
          </Collapse>
        </div>
        <div className="cards">
          {loading ? (
            <div className="loading">
              <CircularProgress sx={{ color: "#ff5c00" }} />
            </div>
          ) : (
            courts.map((item) => {
              return (
                <CardCourt
                  onClick={() => setSelectedCourt(item)}
                  key={item.id}
                  court={{ ...item }}
                  onStatusChange={handleStatusChange}
                />
              );
            })
          )}
        </div>
        <div>
          <Modal
            open={isModalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-title"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                borderRadius: 1,
                padding: 4,
                boxShadow: 24,
                width: "50%",
              }}
            >
              <h2 id="modal-title">Adicionar quadra</h2>
              <div>
                <AddCourtModal closeModal={handleModalClose}></AddCourtModal>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      {!!selectedCourt ? (
        <CourtSchedule
          court={selectedCourt}
          onClose={() => setSelectedCourt(null)}
        />
      ) : null}
    </div>
  );
}

export default Courts;

const token = localStorage.getItem(
  Object.keys(localStorage).find((key) => key.endsWith(".accessToken"))
);
console.log(token);

const path = "https://dev.api.squares.app.br/appointments?";
const pathAux = "https://dev.api.squares.app.br/appointments";

export const getData = async (queryParams = {}) => {
  try {
    const url = new URL(path);
    Object.keys(queryParams).forEach((key) =>
      url.searchParams.append(key, queryParams[key])
    );

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Erro ao fazer requisição:", error);
      });
    return response;
  } catch (error) {
    console.error("Erro ao fazer requisição:", error);
    return null;
  }
};

export const AddScheduleService = async (queryParams = {}) => {
  try {
    const url = new URL(pathAux);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(queryParams),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Erro ao fazer requisição:", error);
      });

    return response;
  } catch (error) {
    console.error("Erro ao chamar o serviço:", error);
    return null;
  }
};

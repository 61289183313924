import React, { useState, useEffect } from "react";
import "./index.css";
import "../button.css";
import { getData } from "../../../services/appointment-service";
import { X, Calendar } from "lucide-react";
import CircularProgress from "@mui/material/CircularProgress";
import { ScheduleModal } from "./scheduleModal/ScheduleModal";
import { ScheduleList } from "./ScheduleList";
import { Box, Modal } from "@mui/material";

export function CourtSchedule({ court, onClose }) {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const data = await getData({ court_id: court.id });
        console.log(data);

        setAppointments(data.appointments);
      } catch (error) {
        console.error("Erro ao buscar os dados", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [court]);

  return (
    <div className="sidebar-schedule">
      <div className="header-schedule">
        <div>
          <span className="title-schedule">{court.name}</span>
          <p className="description-schedule">{court.court_type}</p>
        </div>
        <button onClick={onClose} className="close-schedule">
          <X className="button-close" />
        </button>
      </div>
      <div className="body-schedule">
        <div className="schedule-title">
          <span className="title">Agendamentos</span>
          <button
            onClick={() => setIsModalOpen(true)}
            className="button button-primary"
          >
            Novo Agendamento
          </button>
        </div>
        <div className="schedules">
          {loading ? (
            <div className="loading">
              <CircularProgress sx={{ color: "#ff5c00" }} />
            </div>
          ) : appointments ? (
            appointments.map((item) => {
              return <ScheduleList appointment={{ ...item }} />;
            })
          ) : (
            <div className="empty-schedule">
              <Calendar className="image" />
              <p className="text-gray-600">Nenhum agendamento encontrado</p>
            </div>
          )}
        </div>
      </div>
      <div>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-title"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: 1,
              padding: 4,
              boxShadow: 24,
              width: "50%",
            }}
          >
            <h2 id="modal-title">Adicionar Agendamento</h2>
            <div>
              <ScheduleModal
                closeModal={handleModalClose}
                court_id={court.id}
              ></ScheduleModal>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
